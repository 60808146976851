import React, { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { z } from 'zod';

import { BusinessEventType } from '../../../../functions/src/shared/business-events';
import { amountToCents, centsToAmount } from '../../../../functions/src/shared/monetary';

import { trpc } from '../../shared/trpc/client';
import { preprocessFloat } from '../../shared/zod-schemas';

import { Button, Footer, FormInput, Spacing } from '../../base-ui/components';

import { AlertError } from '../components/alert-error';
import { SelectRentAmountContent } from '../components/select-rent-amount-content';
import { useUserData } from '../contexts/user-data-context';
import { shouldPrefill } from '../utils/handover-should-prefill';
import { useTrackOnboardingEvent } from '../utils/track-onboarding-event';

const schema = z.object({
	amount: preprocessFloat(z.number().gt(0).lt(100_000)),
});

type Schema = z.infer<typeof schema>;

type Props = {
	onNext: () => void;
};

export const IndirectSelectRentAmount = ({ onNext }: Props) => {
	const trackOnboardingEvent = useTrackOnboardingEvent();
	const trpcUtils = trpc.useUtils();
	useEffect(
		() => trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingIndirectSelectRentAmountStart }),
		[],
	);

	const { home, userStatus } = useUserData();
	const amountToPrefill =
		shouldPrefill({ userStatus, home }) && home?.residency?.manualResidencyRentAmountCents
			? centsToAmount(home.residency.manualResidencyRentAmountCents)
			: undefined;

	const form = useForm<Schema>({
		resolver: zodResolver(schema),
		defaultValues: {
			amount: `${amountToPrefill}` as unknown as number, // this gets passed to the input and needs to be a string for it to work
		},
	});

	const saveRentAmount = trpc.user.selectIndirectRentAmount.useMutation({
		onSuccess: () => {
			trpcUtils.user.home.invalidate();
			onNext();
		},
	});

	const submit = async ({ amount }: Schema) => {
		saveRentAmount.mutate({ amountCents: amountToCents(amount) });
	};

	const intl = useIntl();

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(submit)}>
				<SelectRentAmountContent hasInitialRentAmount={false} />

				<Spacing $size="m" />
				<FormInput
					name="amount"
					placeholder={intl.formatMessage({
						defaultMessage: 'Rent amount',
						id: 'account-settings.rent-amount.label',
					})}
					testId="onboarding-indirect-select-rent-input-amount"
					inputMode="decimal"
					type="number"
					prefix="$"
				/>
				{saveRentAmount.isError && <AlertError />}
				<Footer>
					<Button
						disabled={saveRentAmount.isLoading}
						htmlType="submit"
						testId="onboarding-indirect-select-rent-button-continue"
					>
						<FormattedMessage defaultMessage="Confirm" id="common.confirm" />
					</Button>
				</Footer>
			</form>
		</FormProvider>
	);
};
