import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { SignUpType } from '../../../../functions/src/shared/user';

import { useMyZenbaseLinks } from '../../shared/my-zenbase-links';

import { ReconnectBank } from '../components/profile/instant-connection-reconnect';
import { useUserData } from '../contexts/hooks';
import {
	AccountConfirmCustomRent,
	AccountOnboardingSurvey,
	AccountSelectSchedule,
	AuthActions,
	AwaitingTransactions,
	CannotValidateEarnings,
	ConfirmCustomRent,
	ConnectBank,
	CustomRentOnboardingLandlordConnected,
	CustomRentOnboardingManualAddressOnboarding,
	CustomRentOnboardingRecommendedUnits,
	CustomRentOnboardingSelectProperty,
	CustomRentOnboardingSelectUnit,
	EnableCustomRent,
	EnterBankDetails,
	ForgotPassword,
	Home,
	LandingAvenueLiving,
	LandlordConnected,
	LandlordPaymentMethod,
	ManualAddress,
	NotFound404,
	RecommendedUnits,
	RegistrationVerifyEmail,
	RegistrationVerifyPhone,
	ResetPassword,
	RoommatesRentAmountConfirm,
	SelectProperty,
	SelectRentAmount,
	SelectSchedule,
	SelectUnit,
	SignIn,
	SignUp,
	StartVerification,
	VerifyPhone,
} from '../pages';
import { AccountUploadLeaseAgreements } from '../pages/account-upload-lease-agreements';
import { AccountCreditBuilderHistory } from '../pages/Account/account-credit-builder-history';
import { AccountCustomRentHistory } from '../pages/Account/account-custom-rent-history';
import { AccountDashboard } from '../pages/Account/account-dashboard';
import { AccountInstaMoneyHistory } from '../pages/Account/account-insta-money-history';
import { AccountPartner } from '../pages/Account/account-partner';
import { AccountSettings } from '../pages/Account/account-settings';
import { ChangeRentAmount } from '../pages/Account/ChangeRentAmount';
import { CreditBuilderAccountSetup } from '../pages/Account/credit-builder-account-setup';
import { CreditBuilderAccountSuccess } from '../pages/Account/credit-builder-account-success';
import { AccountCreditReportingLineOfCredit } from '../pages/Account/credit-reporting-line-of-credit';
import { DisableCustomRent } from '../pages/Account/DisableCustomRent';
import { AgreementLineOfCredit } from '../pages/agreement/agreement-line-of-credit';
import { AgreementPad } from '../pages/agreement/agreement-pad';
import { Checkout } from '../pages/checkout';
import { CreditBuilderSetupState } from '../pages/credit-builder-onboarding/credit-builder-onboarding.service';
import { CreditBuilderOnboardingSetup } from '../pages/custom-rent-onboarding/5-1-credit-builder-setup';
import { CreditBuilderOnboardingSuccess } from '../pages/custom-rent-onboarding/5-2-credit-builder-success';
import { CreditReportingLineOfCredit } from '../pages/custom-rent-onboarding/11-credit-builder-line-of-credit';
import { CustomRentOnboardingSuccess1 } from '../pages/custom-rent-onboarding/14-1-onboarding-success';
import { CustomRentOnboardingSuccess2 } from '../pages/custom-rent-onboarding/14-2-onboarding-success';
import { CustomRentOnboardingSuccess3 } from '../pages/custom-rent-onboarding/14-3-onboarding-success';
import { CustomRentOnboardingSuccess4 } from '../pages/custom-rent-onboarding/14-4-onboarding-success';
import { CustomRentWaitlist } from '../pages/custom-rent-onboarding/waitlist.page';
import { DirectDebitCardDetails } from '../pages/direct-debit/direct-debit-card-details';
import { DirectDebitCardSuccess } from '../pages/direct-debit/direct-debit-card-success';
import { DirectDebitSetup } from '../pages/direct-debit/direct-debit-setup';
import { InstaMoneyChoosePaybackDate } from '../pages/insta-money/choose-payback-date';
import { InstaMoneyConfirmPaybackDate } from '../pages/insta-money/confirm-payback-date';
import { InstaMoneyRequest } from '../pages/insta-money/request';
import { InstaMoneyTip } from '../pages/insta-money/tip';
import { InstaMoneyUse } from '../pages/insta-money/use';
import { WhyNoTip } from '../pages/insta-money/why-no-tip';
import { SignOut } from '../pages/login/sign-out';
import { MicroDepositPending } from '../pages/manual-bank-onboarding/micro-deposit-pending';
import { MicroDepositVerification } from '../pages/manual-bank-onboarding/micro-deposit-verification';
import { PendingVerification } from '../pages/manual-bank-onboarding/pending-verification';
import { UploadBankStatementsApp } from '../pages/manual-bank-onboarding/upload-bank-statements';
import { PaymentPortal } from '../pages/payment-portal';
import { PaymentStatus } from '../pages/payment-status';
import { Handover } from '../pages/registration/handover';
import { HandoverDataTransfer } from '../pages/registration/handover-data-transfer';
import { Invite } from '../pages/registration/invite';
import { CountryNotSupported } from '../pages/residency/country-not-supported';
import { ConfirmRentPayment } from '../templates/confirm-rent-payment';
import { CreditBuilderHistoryReporting } from '../templates/credit-builder-history-reporting';
import { CreditBuilderIndirectLastMonthOfLease } from '../templates/credit-builder-indirect-last-month-of-lease';
import { CreditBuilderPaymentCompleted } from '../templates/credit-builder-payment-completed';
import { CreditBuilderSelectSubscriptionType } from '../templates/credit-builder-select-subscription-type';
import { DidYouMoveOut } from '../templates/did-you-move-out';
import { BankConnectionRoutes } from './bank-connection-routes';
import { links, paths } from './paths';
import { RouteReplaceNavigate } from './RouteReplaceNavigate';
import {
	AccountBankConnectWrapper,
	Blank,
	InstaMoneyWrapper,
	ProtectedRoutes,
	Registration,
	ResidencyChangeWrapper,
	ResidencyOnboardingWrapper,
	UnprotectedRoutes,
} from './RouteWrappers';

export const Router = () => {
	const { userStatus } = useUserData();
	const navigate = useNavigate();
	// LOADING user status is handled by UserDataProvider, by showing SplashScreen
	const myZenbaseLinks = useMyZenbaseLinks();
	return (
		<Routes>
			<Route path={paths.DEFAULT.ROOT} element={<UnprotectedRoutes userStatus={userStatus} />}>
				<Route path={paths.DEFAULT.ROOT} element={<Home />} />
				<Route path={paths.DEFAULT.SIGN_IN} element={<SignIn />} />
				<Route path={paths.DEFAULT.VERIFY_PHONE} element={<VerifyPhone />} />
				<Route path={paths.DEFAULT.FORGOT_PASSWORD} element={<ForgotPassword />} />
				<Route path={paths.DEFAULT.INVITE} element={<Blank />}>
					<Route index element={<Invite />} />
					<Route path=":secret" element={<Invite />} />
				</Route>
				<Route path={paths.DEFAULT.HANDOVER.ROOT} element={<Blank />}>
					<Route path={paths.DEFAULT.HANDOVER.DATA_TRANSFER} element={<HandoverDataTransfer />} />
					<Route path={paths.DEFAULT.HANDOVER.ROOT} index element={<Handover />} />
					<Route path={paths.DEFAULT.HANDOVER.INVITE} element={<Handover />} />
				</Route>

				{/* landing */}
				<Route path={paths.DEFAULT.AVENUE_LIVING} element={<LandingAvenueLiving />} />
			</Route>

			<Route path={paths.DEFAULT.AUTH_ACTIONS} element={<AuthActions />} />
			<Route path={paths.DEFAULT.SIGN_OUT} element={<SignOut />} />
			<Route path={paths.DEFAULT.RESET_PASSWORD} element={<ResetPassword />} />
			<Route path={paths.DEFAULT.AGREEMENT.ROOT} element={<Blank />}>
				<Route path={paths.DEFAULT.AGREEMENT.LINE_OF_CREDIT} element={<AgreementLineOfCredit />} />
				<Route path={paths.DEFAULT.AGREEMENT.PAD} element={<AgreementPad />} />
			</Route>

			<Route path={paths.REGISTRATION.ROOT} element={<Registration userStatus={userStatus} />}>
				<Route path={paths.REGISTRATION.USER.ROOT} element={<Blank />}>
					<Route path={paths.REGISTRATION.USER.SIGN_UP} element={<SignUp />} />
					<Route
						path={paths.REGISTRATION.USER.SIGN_UP_CREDIT_BUILDER}
						element={<SignUp type={SignUpType.CreditBuilder} />}
					/>
					<Route path={paths.REGISTRATION.USER.SIGN_UP_REFERRAL_PARTNER} element={<SignUp />} />
					<Route path={paths.REGISTRATION.USER.VERIFY_EMAIL} element={<RegistrationVerifyEmail />} />
					<Route path={paths.REGISTRATION.USER.VERIFY_PHONE} element={<RegistrationVerifyPhone />} />
				</Route>

				<Route path={paths.REGISTRATION.RESIDENCY.ROOT} element={<ResidencyOnboardingWrapper />}>
					<Route
						path={paths.REGISTRATION.RESIDENCY.SELECT_PROPERTY}
						element={<CustomRentOnboardingSelectProperty />}
					/>
					<Route
						path={paths.REGISTRATION.RESIDENCY.SELECT_UNIT}
						element={<CustomRentOnboardingSelectUnit />}
					/>
					<Route
						path={paths.REGISTRATION.RESIDENCY.LANDLORD_CONNECTED}
						element={<CustomRentOnboardingLandlordConnected />}
					/>
					<Route
						path={paths.REGISTRATION.RESIDENCY.MANUAL_ADDRESS}
						element={<CustomRentOnboardingManualAddressOnboarding />}
					/>
					<Route
						path={paths.REGISTRATION.RESIDENCY.RECOMMENDED_UNITS}
						element={<CustomRentOnboardingRecommendedUnits />}
					/>
				</Route>
				<Route path={paths.REGISTRATION.COUNTRY_NOT_SUPPORTED} element={<CountryNotSupported />} />
				<Route path={paths.REGISTRATION.ONBOARDING_SURVEY} element={<AccountOnboardingSurvey />} />
				<Route path={paths.REGISTRATION.LANDLORD_PAYMENT_METHOD} element={<LandlordPaymentMethod />} />

				<Route
					path={`${links.REGISTRATION.BANK.ROOT}/*`}
					element={
						<BankConnectionRoutes
							links={links.REGISTRATION.BANK}
							paths={paths.REGISTRATION.BANK}
							variant="custom-rent"
						/>
					}
				/>

				<Route path={paths.REGISTRATION.CONFIGURE.ROOT} element={<Blank />}>
					<Route path={paths.REGISTRATION.CONFIGURE.SELECT_RENT_AMOUNT} element={<SelectRentAmount />} />
					<Route
						path={paths.REGISTRATION.CONFIGURE.ROOMMATES_RENT_AMOUNT_CONFIRM}
						element={<RoommatesRentAmountConfirm />}
					/>
					<Route path={paths.REGISTRATION.CONFIGURE.SELECT_SCHEDULE} element={<SelectSchedule />} />
					<Route path={paths.REGISTRATION.CONFIGURE.CONFIRM_CUSTOM_RENT} element={<ConfirmCustomRent />} />
				</Route>

				<Route path={paths.REGISTRATION.ONBOARDING_SUCCESS.ROOT} element={<Blank />}>
					<Route index element={<CustomRentOnboardingSuccess1 />} />
					<Route
						path={paths.REGISTRATION.ONBOARDING_SUCCESS.LANDLORD}
						element={<CustomRentOnboardingSuccess2 />}
					/>
					<Route
						path={paths.REGISTRATION.ONBOARDING_SUCCESS.RESCHEDULE}
						element={<CustomRentOnboardingSuccess3 />}
					/>
					<Route
						path={paths.REGISTRATION.ONBOARDING_SUCCESS.SUPPORT}
						element={<CustomRentOnboardingSuccess4 />}
					/>
				</Route>

				<Route path={paths.REGISTRATION.CUSTOM_RENT_WAITLIST} element={<CustomRentWaitlist />} />

				<Route
					path={paths.REGISTRATION.CUSTOM_RENT_CREDIT_BUILDER}
					element={
						<CreditReportingLineOfCredit
							onSignUpNavigateLink={links.REGISTRATION.CONFIGURE.CONFIRM_CUSTOM_RENT}
						/>
					}
				/>

				{/* credit builder */}
				<Route path={paths.REGISTRATION.CREDIT_BUILDER.ROOT} element={<Blank />}>
					<Route path={paths.REGISTRATION.CREDIT_BUILDER.SETUP.ROOT}>
						<Route index element={<CreditBuilderOnboardingSetup />} />
						<Route
							path={`${paths.REGISTRATION.CREDIT_BUILDER.SETUP.BANK.ROOT}/*`}
							element={
								<BankConnectionRoutes
									links={links.REGISTRATION.CREDIT_BUILDER.SETUP.BANK}
									paths={paths.REGISTRATION.CREDIT_BUILDER.SETUP.BANK}
									variant="credit-builder"
									onCompletedNavigateParams={[
										links.REGISTRATION.CREDIT_BUILDER.SETUP.ROOT,
										{ state: 'step-confirm-rent-reporting-5' } satisfies {
											state: CreditBuilderSetupState;
										},
									]}
								/>
							}
						/>
					</Route>
					<Route
						path={paths.REGISTRATION.CREDIT_BUILDER.HISTORY_REPORTING}
						element={<CreditBuilderHistoryReporting variant="onboarding" />}
					/>
					<Route
						path={paths.REGISTRATION.CREDIT_BUILDER.SUBSCRIPTION_TYPE}
						element={
							<CreditBuilderSelectSubscriptionType nextPage={links.REGISTRATION.CREDIT_BUILDER.SUCCESS} />
						}
					/>
					<Route
						path={paths.REGISTRATION.CREDIT_BUILDER.SUCCESS}
						element={<CreditBuilderOnboardingSuccess />}
					/>
					<Route
						path={paths.REGISTRATION.CREDIT_BUILDER.PAYMENT_COMPLETED}
						element={<CreditBuilderPaymentCompleted variant="onboarding" />}
					/>
				</Route>

				<Route path={paths.REGISTRATION.PAYMENT_PORTAL} element={<PaymentPortal />} />

				<Route path={paths.REGISTRATION.DIRECT_DEBIT.ROOT} element={<Blank />}>
					<Route path={paths.REGISTRATION.DIRECT_DEBIT.SETUP} element={<DirectDebitSetup />} />
					<Route path={paths.REGISTRATION.DIRECT_DEBIT.CARD_DETAILS} element={<DirectDebitCardDetails />} />
				</Route>
			</Route>

			<Route path={paths.ACCOUNT.ROOT} element={<ProtectedRoutes userStatus={userStatus} />}>
				<Route path={paths.ACCOUNT.DASHBOARD} element={<AccountDashboard />} />
				<Route path={paths.ACCOUNT.SETTINGS} element={<AccountSettings />} />
				<Route path={paths.ACCOUNT.DISABLE_CUSTOM_RENT} element={<DisableCustomRent />} />
				<Route path={paths.ACCOUNT.CHANGE_RENT_AMOUNT} element={<ChangeRentAmount />} />
				<Route path={paths.ACCOUNT.UPLOAD_STATEMENTS} element={<UploadBankStatementsApp />} />
				<Route path={paths.ACCOUNT.MICRO_DEPOSIT_VERIFICATION} element={<MicroDepositVerification />} />
				<Route path={paths.ACCOUNT.BANK_VERIFICATION_PENDING} element={<PendingVerification />} />
				<Route path={paths.ACCOUNT.DID_YOU_MOVE_OUT} element={<DidYouMoveOut />} />

				<Route path={paths.ACCOUNT.INSTA_MONEY.ROOT} element={<InstaMoneyWrapper />}>
					<Route path={paths.ACCOUNT.INSTA_MONEY.REQUEST} element={<InstaMoneyRequest />} />
					<Route path={paths.ACCOUNT.INSTA_MONEY.USE} element={<InstaMoneyUse />} />
					<Route
						path={paths.ACCOUNT.INSTA_MONEY.CHOOSE_PAYBACK_DATE}
						element={<InstaMoneyChoosePaybackDate />}
					/>
					<Route path={paths.ACCOUNT.INSTA_MONEY.TIP} element={<InstaMoneyTip />} />
					<Route path={paths.ACCOUNT.INSTA_MONEY.NO_TIP_REASON} element={<WhyNoTip />} />
					<Route
						path={paths.ACCOUNT.INSTA_MONEY.CONFIRM_PAYBACK_DATE}
						element={<InstaMoneyConfirmPaybackDate />}
					/>
				</Route>

				<Route path={paths.ACCOUNT.HISTORY.ROOT} element={<Blank />}>
					<Route path={paths.ACCOUNT.HISTORY.CUSTOM_RENT} element={<AccountCustomRentHistory />} />
					<Route path={paths.ACCOUNT.HISTORY.CREDIT_BUILDER} element={<AccountCreditBuilderHistory />} />
					<Route path={paths.ACCOUNT.HISTORY.INSTA_MONEY} element={<AccountInstaMoneyHistory />} />
				</Route>
				<Route
					path={paths.ACCOUNT.CUSTOM_RENT_CREDIT_REPORTING}
					element={<CreditReportingLineOfCredit closeButton onSignUpNavigateLink={links.ACCOUNT.DASHBOARD} />}
				/>

				<Route path={paths.ACCOUNT.BANK.ROOT} element={<AccountBankConnectWrapper />}>
					<Route path={paths.ACCOUNT.BANK.CONNECT_BANK} element={<ConnectBank />} />
					<Route
						path={paths.ACCOUNT.BANK.AWAITING_TRANSACTIONS}
						element={
							<AwaitingTransactions
								titleText={
									<FormattedMessage
										defaultMessage="Your account is {br} almost set up"
										id="awaiting-transactions-almost-setup"
									/>
								}
								connectAgainLink={links.ACCOUNT.BANK.CONNECT_BANK}
								connectManuallyLink={links.ACCOUNT.BANK.MANUAL_ENTER_BANK_DETAILS}
							/>
						}
					/>
					<Route
						path={paths.ACCOUNT.BANK.CANNOT_VALIDATE_EARNINGS}
						element={<CannotValidateEarnings reconnectBankLink={links.ACCOUNT.BANK.RECONNECT_BANK} />}
					/>

					<Route path={paths.ACCOUNT.BANK.MANUAL_ENTER_BANK_DETAILS} element={<EnterBankDetails />} />
					<Route path={paths.ACCOUNT.BANK.MANUAL_START_VERIFICATION} element={<StartVerification />} />
					<Route path={paths.ACCOUNT.BANK.MANUAL_PENDING_VERIFICATION} element={<MicroDepositPending />} />
					<Route path={paths.ACCOUNT.BANK.RECONNECT_BANK} element={<ReconnectBank />} />
					<Route
						path={paths.ACCOUNT.BANK.AWAITING_RECONNECT_TRANSACTIONS}
						element={
							<AwaitingTransactions
								titleText={
									<FormattedMessage
										defaultMessage="Your account is {br} almost reconnected"
										id="awaiting-transactions-almost-reconnected"
										values={{ br: <br /> }}
									/>
								}
								connectAgainLink={links.ACCOUNT.BANK.CONNECT_BANK}
								connectManuallyLink={links.ACCOUNT.BANK.MANUAL_ENTER_BANK_DETAILS}
							/>
						}
					/>
				</Route>

				<Route path={paths.ACCOUNT.CONFIGURE.ROOT} element={<Blank />}>
					<Route path={paths.ACCOUNT.CONFIGURE.SELECT_SCHEDULE} element={<AccountSelectSchedule />} />
					<Route
						path={paths.ACCOUNT.CONFIGURE.CUSTOM_RENT_LOC}
						element={
							<AccountCreditReportingLineOfCredit
								onSignUpNavigateLink={links.ACCOUNT.CONFIGURE.CONFIRM_CUSTOM_RENT}
								closeButton
							/>
						}
					/>
					<Route path={paths.ACCOUNT.CONFIGURE.CONFIRM_CUSTOM_RENT} element={<AccountConfirmCustomRent />} />
					<Route path={paths.ACCOUNT.CONFIGURE.ENABLE_CUSTOM_RENT} element={<EnableCustomRent />} />
				</Route>

				<Route path={paths.ACCOUNT.RESIDENCY.ROOT} element={<ResidencyChangeWrapper />}>
					<Route path={paths.ACCOUNT.RESIDENCY.SELECT_PROPERTY} element={<SelectProperty />} />
					<Route path={paths.ACCOUNT.RESIDENCY.SELECT_UNIT} element={<SelectUnit />} />
					<Route path={paths.ACCOUNT.RESIDENCY.MANUAL_ADDRESS} element={<ManualAddress />} />
					<Route path={paths.ACCOUNT.RESIDENCY.RECOMMENDED_UNITS} element={<RecommendedUnits />} />
					<Route path={paths.ACCOUNT.RESIDENCY.LANDLORD_CONNECTED} element={<LandlordConnected />} />
				</Route>

				<Route path={paths.ACCOUNT.PARTNER} element={<AccountPartner />} />

				{/* credit builder */}
				<Route path={paths.ACCOUNT.CREDIT_BUILDER.ROOT} element={<Blank />}>
					<Route path={paths.ACCOUNT.CREDIT_BUILDER.SETUP} element={<CreditBuilderAccountSetup />} />
					<Route
						path={paths.ACCOUNT.CREDIT_BUILDER.HISTORY_REPORTING}
						element={<CreditBuilderHistoryReporting variant="account" />}
					/>
					<Route
						path={paths.ACCOUNT.CREDIT_BUILDER.SUBSCRIPTION_TYPE}
						element={
							<CreditBuilderSelectSubscriptionType nextPage={links.ACCOUNT.CREDIT_BUILDER.SUCCESS} />
						}
					/>
					<Route path={paths.ACCOUNT.CREDIT_BUILDER.SUCCESS} element={<CreditBuilderAccountSuccess />} />
					<Route
						path={paths.ACCOUNT.CREDIT_BUILDER.PAYMENT_COMPLETED}
						element={<CreditBuilderPaymentCompleted variant="account" />}
					/>
					<Route
						path={paths.ACCOUNT.CREDIT_BUILDER.INDIRECT_LAST_MONTH_OF_LEASE}
						element={<CreditBuilderIndirectLastMonthOfLease />}
					/>

					<Route
						path={paths.ACCOUNT.CREDIT_BUILDER.CONFIRM_RENT_PAYMENT}
						element={
							<ConfirmRentPayment
								onNext={() => {
									navigate(links.ACCOUNT.DASHBOARD);
								}}
								onConnectDifferentAccount={() => {
									navigate(links.ACCOUNT.BANK.CONNECT_BANK);
								}}
							/>
						}
					/>
				</Route>

				<Route path={paths.ACCOUNT.PAYMENT_PORTAL} element={<PaymentPortal />} />
				<Route path={paths.ACCOUNT.UPLOAD_LEASE_AGREEMENTS} element={<AccountUploadLeaseAgreements />} />
			</Route>

			<Route path={paths.ACCOUNT.DIRECT_DEBIT.ROOT} element={<Blank />}>
				<Route path={paths.ACCOUNT.DIRECT_DEBIT.SETUP} element={<DirectDebitSetup />} />
				<Route path={paths.ACCOUNT.DIRECT_DEBIT.CARD_DETAILS} element={<DirectDebitCardDetails />} />
				<Route path={paths.ACCOUNT.DIRECT_DEBIT.SUCCESS} element={<DirectDebitCardSuccess />} />
			</Route>

			<Route path={paths.DEFAULT.CHECKOUT} element={<Checkout />} />
			<Route path={paths.DEFAULT.PAYMENT_STATUS} element={<PaymentStatus />} />

			{/* old ui redirect fallbacks */}
			<Route path="/login" element={<Navigate to={links.DEFAULT.SIGN_IN} />} />
			<Route path="/my/home" element={<Navigate to={links.ACCOUNT.DASHBOARD} />} />
			<Route path="/landing" element={<Navigate to={links.DEFAULT.ROOT} />} />
			<Route path="/terms-of-use" element={<RouteReplaceNavigate url={myZenbaseLinks.terms} />} />
			<Route path="/privacy-policy" element={<RouteReplaceNavigate url={myZenbaseLinks.privacy} />} />

			<Route path="*" element={<NotFound404 />} />
		</Routes>
	);
};
